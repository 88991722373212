<template>

  <div class="card">
    <div class="card-body tab-content">
      <h6>NOTIFICATION SETTINGS</h6>
      <hr>
      <form>
        <div class="form-group">
          <label class="d-block mb-0">Security Alerts</label>
          <div class="small text-muted mb-3">Receive security alert notifications via email</div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheck1" checked="">
            <label class="custom-control-label" for="customCheck1">Email each time a vulnerability is found</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheck2" checked="">
            <label class="custom-control-label" for="customCheck2">Email a digest summary of vulnerability</label>
          </div>
        </div>
        <div class="form-group mb-0">
          <label class="d-block">SMS Notifications</label>
          <ul class="list-group list-group-sm">
            <li class="list-group-item has-icon">
              Comments
              <div class="custom-control custom-control-nolabel custom-switch ml-auto">
                <input type="checkbox" class="custom-control-input" id="customSwitch1" checked="">
                <label class="custom-control-label" for="customSwitch1"></label>
              </div>
            </li>
            <li class="list-group-item has-icon">
              Updates From People
              <div class="custom-control custom-control-nolabel custom-switch ml-auto">
                <input type="checkbox" class="custom-control-input" id="customSwitch2">
                <label class="custom-control-label" for="customSwitch2"></label>
              </div>
            </li>
            <li class="list-group-item has-icon">
              Reminders
              <div class="custom-control custom-control-nolabel custom-switch ml-auto">
                <input type="checkbox" class="custom-control-input" id="customSwitch3" checked="">
                <label class="custom-control-label" for="customSwitch3"></label>
              </div>
            </li>
            <li class="list-group-item has-icon">
              Events
              <div class="custom-control custom-control-nolabel custom-switch ml-auto">
                <input type="checkbox" class="custom-control-input" id="customSwitch4" checked="">
                <label class="custom-control-label" for="customSwitch4"></label>
              </div>
            </li>
            <li class="list-group-item has-icon">
              Pages You Follow
              <div class="custom-control custom-control-nolabel custom-switch ml-auto">
                <input type="checkbox" class="custom-control-input" id="customSwitch5">
                <label class="custom-control-label" for="customSwitch5"></label>
              </div>
            </li>
          </ul>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
export default {
  name: 'UserSettings',

  data() {
    return {
      active_category: '',
      open_questions: [],

      categories: [
        {id: 'test', icon: 'cart', label: 'Using the dashboard'},
        {id: 'test2', icon: 'truck', label: 'Contact and Support'},
        {id: 'test3', icon: 'box', label: 'Some other stuff'},
        {id: 'test4', icon: 'credit-card', label: 'Some empty stuff'},
        {id: 'test5', icon: 'chat', label: 'Some other empty stuff'},
      ],

      faqs: [
        {category: 'test', question: 'Lorem ipsum dolor sit.', answer: 'Lorem ipsum dolor sit amet consectetur.'},
        {category: 'test', question: 'Lorem ipsum dolor sit amet.', answer: 'Lorem ipsum dolor sit amet consectetur.'},
        {category: 'test2', question: 'Lorem ipsum dolor sit amet consectetur.', answer: 'Lorem ipsum dolor sit amet consectetur.'},
        {category: 'test2', question: 'Lorem ipsum dolor sit amet.', answer: 'Lorem ipsum dolor sit amet consectetur.'},
        {category: 'test3', question: 'Lorem ipsum dolor.', answer: 'Lorem ipsum dolor sit <i>amet</i> consectetur.'},
      ]
    }
  },
  computed: {},
  methods: {
    setCategory(id) {
      this.active_category = null
      this.open_questions = []
      //setTimeout(() => {
        this.active_category = id
      //}, 300)
    },
    toggleQuestionOpen(index) {
      if ( this.open_questions.includes(index) ) {
        this.open_questions = this.open_questions.filter( x => x !== index)
      } else {
        this.open_questions.push(index)
      }
    },
    faqsInCategory( cat ) {
      return this.faqs.filter(x => x.category == cat)
    },
  },
};
</script>

<style lang="scss" scoped>
.question .answer {
  display: none;
}
.question.open .answer {
  display: block;
}

.row.small-gutters {
  margin-right: -5px;
  margin-left: -5px;
  & > .col, & > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}

/* VERSION 2 */
.category {
  cursor: pointer;
}

li { 
  font-size: 1.1rem;
  & > div {
    font-size: 0.9rem;
  }
}

@media (min-width: 992px) {
  .category { font-size: 1.2em; }
  svg { font-size: 2.4rem; }
}
/* END VERSION 2 */

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>